import { Box, Stack } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import Logo from "./Logo";
import { MotionInView, varFadeIn } from "./animate";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(() => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "Background.paper",
}));

export default function LoadingScreen({ ...other }) {
  return (
    <RootStyle {...other}>
      <Stack alignItems="center" justifyContent="center">
        <MotionInView variants={varFadeIn}>
          <Stack alignItems="center" justifyContent="center">
            <Box
              component="img"
              src="/logo/logo.png"
              sx={{ width: "250px", height: "250px", objectFit: "contain" }}
            />
            <Box
              component="img"
              src="/icons/loading.gif"
              sx={{ width: "450px", height: "50px", objectFit: "cover" }}
            />
            <Box sx={{ height: "140px" }} />
          </Stack>
        </MotionInView>
      </Stack>
      {/* <motion.div
                initial={{ rotateY: 0 }}
                animate={{ rotateY: 360 }}
                transition={{
                    duration: 2,
                    ease: "easeInOut",
                    repeatDelay: 1,
                    repeat: Infinity,
                }}
            >
                <Logo sx={{ width: 64, height: 64 }} />
            </motion.div>

            <Box
                component={motion.div}
                animate={{
                    scale: [1.2, 1, 1, 1.2, 1.2],
                    rotate: [270, 0, 0, 270, 270],
                    opacity: [0.25, 1, 1, 1, 0.25],
                    borderRadius: ["25%", "25%", "50%", "50%", "25%"],
                }}
                transition={{
                    ease: "linear",
                    duration: 3.2,
                    repeat: Infinity,
                }}
                sx={{
                    width: 100,
                    height: 100,
                    borderRadius: "25%",
                    position: "absolute",
                    border: (theme) =>
                        `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
                }}
            />

            <Box
                component={motion.div}
                animate={{
                    scale: [1, 1.2, 1.2, 1, 1],
                    rotate: [0, 270, 270, 0, 0],
                    opacity: [1, 0.25, 0.25, 0.25, 1],
                    borderRadius: ["25%", "25%", "50%", "50%", "25%"],
                }}
                transition={{
                    ease: "linear",
                    duration: 3.2,
                    repeat: Infinity,
                }}
                sx={{
                    width: 120,
                    height: 120,
                    borderRadius: "25%",
                    position: "absolute",
                    border: (theme) =>
                        `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
                }}
            /> */}
    </RootStyle>
  );
}
